<template>
  <div class="payFailed">
    <b-card>
      <div class="icon">
        <img src="@/assets/images/payFailed.png" alt="" />
      </div>
      <div class="details">
        <span v-if="$route.query.stripe_status">Something went wrong!</span>
        <span v-else>
          {{ $t("PayMoney.ba9@payment_failed_plea") }}
        </span>
        <span v-if="$route.query.orderId">{{ $t("PayMoney.0a5@orderid") }} :
          {{ $route.query.orderId }}</span>
        <span v-if="$route.query.transactionId">{{ $t("PayMoney.139@paymentid") }} :
          {{ $route.query.transactionId }}</span>
      </div>
      <div class="btnGroup">
        <button class="btn btn-primary" @click="goBack">
          {{ $t("PayMoney.0d7@confirm") }}
        </button>
      </div>
    </b-card>
  </div>
</template>
<script>
  export default {
    data() {
      return {
      }
    },

    mounted() {
      localStorage.removeItem("PayId");
    },

    methods: {
      goBack() {
        if (this.$route.query.model_type) {
          window.history.go(-2)
        } else {
          window.history.go(-1)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .payFailed {
    padding: 20px;

    .card-body {
      padding: 50px;

      .icon {
        text-align: center;
      }

      .details {
        border: 1px solid #ff9b8a;
        padding: 10px;
        background: rgba(255, 245, 244, 1);
        color: #ff9b8a;
        text-align: center;
        margin-top: 20px;

        span {
          display: block;
        }
      }

      .btnGroup {
        text-align: center;
        padding-top: 30px;
      }
    }
  }
</style>